import { cryptage } from '../../services/cryptage'

export const getEtudiants = ({ commit, rootState }) => {
  return new Promise((resolve, reject) => {
    cryptage.decryptMessage(localStorage.getItem('id'), localStorage.getItem('token'))
      .then((decrypted) => {
        const id = parseInt(decrypted)

        window.axios.get('/parent/enfants/' + id)
          .then((response) => {
            console.log(decrypted)
            // eslint-disable-next-line eqeqeq
            if (response.data.code == 302) {
              reject(response)
            } else {
              commit('etudiants/setEtudiants', response.data.data, { root: true })
              resolve(response)
            }
          })
          .catch((err) => {
            reject(err)
          })
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const sendEtudiant = (compte, entite, matricule, codeparent, datenaissance, lieunaissance) => {
  console.log(compte, entite, matricule, codeparent, datenaissance, lieunaissance)
  return new Promise((resolve, reject) => {
    window.axios.post('/parent/ajout-enfant', {
      id_compte: compte,
      id_entite: entite,
      matricule: matricule,
      codeparent: codeparent,
      datenaissance: datenaissance,
      lieunaissance: lieunaissance
    }).then((response) => {
      // eslint-disable-next-line eqeqeq
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
