// Importez le module crypto si nécessaire
export const cryptage = {
  async encryptMessage (data, cle) {
    const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.- '
    const alphaLength = alpha.length
    let txtChiffre = ''
    const txtClair = data
    const txtClairLength = txtClair.length

    for (let i = 0; i < txtClairLength; i++) {
      const charClair = txtClair[i]
      const nbrCharClair = alpha.indexOf(charClair)
      const txtCle = cle
      const txtCleLength = txtCle.length
      const charCle = txtCle[i % txtCleLength]
      const nbrCharCle = alpha.indexOf(charCle)
      const nbrCharChiffre = (nbrCharClair + nbrCharCle) % alphaLength
      const charChiffre = alpha[nbrCharChiffre]
      txtChiffre += charChiffre
    }

    return txtChiffre
  },

  async decryptMessage (data, cle) {
    const alpha = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789.- '
    const alphaLength = alpha.length
    let txtClair = ''
    const txtChiffre = data
    const txtChiffreLength = txtChiffre.length

    for (let i = 0; i < txtChiffreLength; i++) {
      const charChiffre = txtChiffre[i]
      const nbrCharChiffre = alpha.indexOf(charChiffre)
      const txtCle = cle
      const txtCleLength = txtCle.length
      const charCle = txtCle[i % txtCleLength]
      const nbrCharCle = alpha.indexOf(charCle)
      const nbrCharClair = (nbrCharChiffre - nbrCharCle + alphaLength) % alphaLength
      const charClair = alpha[nbrCharClair]
      txtClair += charClair
    }

    return txtClair
  }
}
