export const inscription = (nom, prenom, telephone, ville, profession, email, password) => {
  return new Promise((resolve, reject) => {
    window.axios.post('/parent/inscription', {
      nom: nom,
      prenom: prenom,
      telephone: telephone,
      ville: ville,
      profession: profession,
      email: email,
      password: password
    }).then((response) => {
      // eslint-disable-next-line eqeqeq
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}

export const valider = (compte, telephone, code) => {
  console.log(telephone)
  return new Promise((resolve, reject) => {
    window.axios.post('/parent/validation-compte', {
      id_compte: compte,
      telephone: telephone,
      code: code
    }).then((response) => {
      // eslint-disable-next-line eqeqeq
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}
