import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import Ls from './services/ls'
import { BASE_URL } from './services/api'

const app = createApp(App)
if (localStorage.getItem('id') != null) {
  app.config.globalProperties.maVariableGlobale = true
} else {
  app.config.globalProperties.maVariableGlobale = false
}

window.axios = axios
axios.defaults.baseURL = BASE_URL
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
window.axios.defaults.headers.common.Authorization = ''
window.axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;application/json'

window.axios.interceptors.request.use(function (config) {
  const AUTH_TOKEN = Ls.get('token')
  if (AUTH_TOKEN) {
    config.headers.Authorization = `Bearer ${AUTH_TOKEN}`
  }
  return config
}, function (error) {
  return Promise.reject(error)
})

global.axios.interceptors.response.use(undefined, function (err) {
  if (err.response) {
    if (err.response.data && err.response.status === 401) {
      console.log(err.response)
      if (err.response.data.code === 4011 || err.response.data.code === 4012) {
        app.config.globalProperties.tokenExpire = true
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Oops... Votre session à expirer.',
          text: 'Vous serez rediriger vers la page de connexion',
          icon: 'error'
        }).then((response) => {
          Ls.set('auth.token', '')
          window.location = '/'
        })
      } else {
        // eslint-disable-next-line no-undef
        Swal.fire({
          title: 'Oops...',
          text: `${(err.response.data.message) ? err.response.data.message : 'Non autorisé'}`,
          icon: 'error'
        })
      }
    } else if (err.response.data && err.response.status === 404) {
      console.log((err.response.data.message) ? err.response.data.message : 'Non autorisé')
    } else if (err.response.data.errors && err.response.status === 402) {
      const errors = JSON.parse(JSON.stringify(err.response.data.errors))
      for (const i in errors) {
        console.log(errors[i])
      }
    } else {
      console.log(err)
    }
  } else {
    // eslint-disable-next-line no-undef
    Swal.fire({
      title: 'Oops...',
      text: `${(err.message) ? err.message : 'Non autorisé'}`,
      icon: 'error'
    })
  }
})

createApp(App).use(store).use(router).mount('#app')

export default app
