import * as getters from './getters.js'
import mutations from './mutations.js'
import * as actions from './actions.js'

const register = {
}

export default {
  namespaced: true,
  state: register,
  getters: getters,
  mutations: mutations,
  actions: actions
}
