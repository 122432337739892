import { createRouter, createWebHistory } from 'vue-router'
import app from '../main.js'

const routes = [
  {
    path: '/',
    name: 'fichier',
    component: () => import(/* webpackChunkName: "about" */ '../views/fichier.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  {
    path: '/validation',
    name: 'validation',
    component: () => import(/* webpackChunkName: "about" */ '../views/validation.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import(/* webpackChunkName: "about" */ '../views/home.vue')
  },
  {
    path: '/addchild',
    name: 'addchild',
    component: () => import(/* webpackChunkName: "about" */ '../views/addchild.vue')
  },
  {
    path: '/cours',
    name: 'programmationCours',
    component: () => import(/* webpackChunkName: "about" */ '../views/programmationCours.vue')
  },
  {
    path: '/examens',
    name: 'programmationExamens',
    component: () => import(/* webpackChunkName: "about" */ '../views/programmationExamens.vue')
  },
  {
    path: '/listUE',
    name: 'listUE',
    component: () => import(/* webpackChunkName: "about" */ '../views/listUE.vue')
  },
  {
    path: '/notesECU',
    name: 'notesECU',
    component: () => import(/* webpackChunkName: "about" */ '../views/notesECU.vue')
  },
  {
    path: '/moyennesUE',
    name: 'moyennesUE',
    component: () => import(/* webpackChunkName: "about" */ '../views/moyennesUE.vue')
  },
  {
    path: '/bilanUE',
    name: 'bilanUE',
    component: () => import(/* webpackChunkName: "about" */ '../views/bilanUE.vue')
  },
  {
    path: '/listRattrapage',
    name: 'listRattrapage',
    component: () => import(/* webpackChunkName: "about" */ '../views/listRattrapage.vue')
  },
  {
    path: '/notesRattrapage',
    name: 'notesRattrapage',
    component: () => import(/* webpackChunkName: "about" */ '../views/notesRattrapage.vue')
  },
  {
    path: '/listReprise',
    name: 'listReprise',
    component: () => import(/* webpackChunkName: "about" */ '../views/listReprise.vue')
  },
  {
    path: '/notesReprise',
    name: 'notesReprise',
    component: () => import(/* webpackChunkName: "about" */ '../views/notesReprise.vue')
  },
  {
    path: '/resultatFin',
    name: 'resultatFin',
    component: () => import(/* webpackChunkName: "about" */ '../views/resultatFin.vue')
  },
  {
    path: '/historique',
    name: 'historique',
    component: () => import(/* webpackChunkName: "about" */ '../views/historique.vue')
  },
  {
    path: '/profil',
    name: 'profil',
    component: () => import(/* webpackChunkName: "about" */ '../views/profil.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Tableau des noms de route non éligibles
const nonEligibleRoutes = ['fichier', 'login', 'register', 'validation']

// Vérification de l'état de la variable isconnect pour les autres routes
router.beforeEach((to, from, next) => {
  if (nonEligibleRoutes.includes(to.name)) {
    console.log('routes eligibles')
    // eslint-disable-next-line eqeqeq
    if (!app.config.globalProperties.tokenExpire && to.name === 'fichier') {
      next()
    } else {
      if (app.config.globalProperties.maVariableGlobale && localStorage.getItem('lastSession')) {
        console.log(app.config.globalProperties.maVariableGlobale)
        if (localStorage.getItem('moyenne') && localStorage.getItem('moyenneUeMaxRattrapage') && localStorage.getItem('libelleAnne') && localStorage.getItem('nomEtudiant') && localStorage.getItem('prenomEtudiant') && localStorage.getItem('matriculeEtudiant') && localStorage.getItem('idEtablissement') && localStorage.getItem('classe') && localStorage.getItem('entite')) {
          localStorage.setItem('lastSession', localStorage.getItem('lastSession'))
          next(localStorage.getItem('lastSession'))
        } else {
          localStorage.setItem('lastSession', '/addchild')
          next('/addchild')
        }
      } else {
        next()
      }
    }
  } else {
    console.log('routes non eligibles')
    if (app.config.globalProperties.maVariableGlobale) {
      if (to.name === 'addchild') {
        localStorage.setItem('lastSession', to.path)
        next()
      } else {
        if (localStorage.getItem('moyenne') && localStorage.getItem('moyenneUeMaxRattrapage') && localStorage.getItem('libelleAnne') && localStorage.getItem('nomEtudiant') && localStorage.getItem('prenomEtudiant') && localStorage.getItem('matriculeEtudiant') && localStorage.getItem('idEtablissement') && localStorage.getItem('classe') && localStorage.getItem('entite')) {
          localStorage.setItem('lastSession', to.path)
          next()
        } else {
          next('/addchild')
        }
      }
    } else {
      next('/login')
    }
  }
})

export default router
