import * as getters from './getters.js'
import mutations from './mutations.js'
import * as actions from './actions.js'

const etudiants = {
}

export default {
  namespaced: true,
  state: etudiants,
  getters: getters,
  mutations: mutations,
  actions: actions
}
