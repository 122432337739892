import { createStore } from 'vuex'
import etudiants from './Etudiants/index.js'
import register from './Register/index.js'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    etudiants,
    register
  }
})
